import { ReactNode } from "react";
import { Button, Spinner } from "react-bootstrap";

interface IButtonProps {
    isLoading?: boolean,
    disabled?: boolean,
    variant?: string,
    showTextOnLoading?: boolean,
    className?: string,
    onClick: () => void,
    children?: ReactNode,
    id?: string,
    size?: 'sm' | 'lg',
}

const ButtonCustom: React.FC<IButtonProps> = (props: IButtonProps) => {
    return <Button id={props.id} className={props.className} size={props.size} variant={props.variant} disabled={props.disabled || props.isLoading} onClick={() => props.onClick()}>
        {props.isLoading && (
            <Spinner animation="border" role="status" size="sm" className="me-2">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )}

        {(!props.isLoading || props.showTextOnLoading) && props.children}
    </Button>
}

export default ButtonCustom;