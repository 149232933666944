import { FC, useState, MouseEvent, ReactElement, forwardRef, useEffect } from "react";
import { Dropdown, DropdownButton, Form, Image } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";

import waveBackground from "../../assets/svg/nav/navbar-wave.svg";
import burgerMenu from "../../assets/svg/hamburger_menu.svg";
import appLogo from "../../assets/images/cmu13-logo.png";
import historyIcon from "../../assets/svg/history.svg";
import logoutIcon from "../../assets/svg/box-arrow-right.svg";

import "./navbar.scss";
import { logout } from "../../utils/cmuAuth";
import { useUser } from "../../hooks/useUser";
import ConfirmModal from "../confirmModal/confirmModal";
import dayjs from "dayjs";
import {
  BUDGET_YEAR_RANGE,
  BUDGET_YEAR_START,
} from "../../constants/appConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH } from "../../constants/path";
import { UserRoleEnum } from "../../enum/userRole";
import { isEnableBypassAuth } from "../../utils/bypass";

type CustomToggleProps = {
  children?: React.ReactNode;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => {};
};

export interface NavbarProps {
  getSelectedLanguage: () => string;
  onChangedLanguage: (language: string) => void;
  toggleSidebar: () => void;
  budgetYear: number;
  onChangedBudgetYear: (year: number) => void;
}

const Navbar: FC<NavbarProps> = (props: NavbarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, logOut } = useUser();
  const location = useLocation();

  const [showChangeYearModal, setShowChangeYearModal] =
    useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<number>(props.budgetYear);
  const [currentRole, setCurrentRole] = useState<number>(UserRoleEnum.ADMIN);

  const handleChangeYear = (budgetyear: number) => {
    setShowChangeYearModal(true);
    setSelectedYear(budgetyear);
  };

  const handleCloseModal = () => {
    setShowChangeYearModal(false);
  };

  const handleConfirmModal = () => {
    setShowChangeYearModal(false);
    props.onChangedBudgetYear(selectedYear);
  };

  useEffect(() => {
    if (user && user.roleId !== undefined) {
      setCurrentRole(user.roleId);
    }
  }, [user])

  const CustomToggle = forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => {
      return (
        <a
          className="user-dropdown"
          href="."
          ref={ref}
          onClick={(e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            props.onClick && props.onClick(e);
          }}
        >
          {props.children}
          <>{user?.name}</>
        </a>
      );
    }
  );

  const renderUserDropDown = (): ReactElement => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} />

        <Dropdown.Menu>
          <Dropdown.Item className="dropdown-log">
            <button
              className="btn"
              onClick={() => {
                navigate("/allocate-budget-logs");
              }}
            >
              <SVG src={historyIcon} className="me-2 mt-1" />
              {t("HISTORY_BUDGET_MANAGEMENT")}
            </button>
          </Dropdown.Item>
          <Dropdown.Item className="dropdown-logout">
            <button className="btn logout" onClick={() => logout(logOut)}>
              <SVG src={logoutIcon} className="me-2 mt-1" />
              {t("LOGOUT")}
            </button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getBudgetYear = () => {
    const thisYear = Number(dayjs(new Date()).format("BBBB"));
    let result = [];
    for (let i = BUDGET_YEAR_START; i < thisYear; i++) {
      result.push(i);
    }
    for (let i = thisYear; i < thisYear + BUDGET_YEAR_RANGE; i++) {
      result.push(i);
    }
    return result;
  };

  const onByPassChange = (value: string) => {
    window.location.href = "/test-login/" + value
  }

  return (
    <>
      <div className="navbar">
        <SVG className="wave" src={waveBackground} />

        <div className="container-fluid">
          <div className="container__left">
            <button
              type="button"
              className="btn hamburger-menu"
              onClick={() => props.toggleSidebar()}
            >
              <SVG src={burgerMenu} />
            </button>
            <Image className="logo mt-2" src={appLogo} />
            <h3>Personal Budgeting</h3>
          </div>

          <div className="container__center">
            {isEnableBypassAuth() && (
              <>
                <Form.Select value={currentRole} onChange={e => onByPassChange(e.target.value)} className="me-2" aria-label="By pass auth">
                  <option value="1">{t("SUPER_ADMIN")}</option>
                  <option value="2">{t("APPROVER")}</option>
                  <option value="3">{t("ADMIN")}</option>
                </Form.Select>
              </>
            )}
          </div>

          <div className="container__right">
            <>
              <DropdownButton
                disabled={location.pathname === PATH.MANAGE_PERSONNEL}
                id="budget-year-ddl"
                variant="secondary"
                title={t("BUDGET_YEAR") + " " + props.budgetYear.toString()}
                defaultValue={props.budgetYear.toString()}
                onSelect={(e) => handleChangeYear(Number(e))}
              >
                {getBudgetYear().map((i) => {
                  return (
                    <Dropdown.Item key={i} eventKey={i}>
                      {t("BUDGET_YEAR")} {i}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>

              {renderUserDropDown()}
            </>
          </div>
        </div>
      </div>
      <ConfirmModal
        isShow={showChangeYearModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirmModal}
        title={t("CHANGE_BUDGET_YEAR_MODAL_TITLE")}
        body={t("CHANGE_BUDGET_YEAR_MODAL_BODY")}
      />
    </>
  );
};

export default Navbar;
