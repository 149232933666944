import { Nav } from "react-bootstrap";

import { ISidebarItem, SIDEBAR_ITEMLIST } from "../../constants/sidebarItem";

import "./sidebar.scss";

import ItemLink from "./item/item";
import { useRole } from "../../hooks/useUser";
import { useEffect, useState } from "react";

export interface SidebarProps {
  appVersion: string;
  isSidebarCollapsed: boolean;
}

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const { isSuperAdmin } = useRole();
  const [sidebarItems, setSidebarItems] =
    useState<ISidebarItem[]>(SIDEBAR_ITEMLIST);

  useEffect(() => {
    let sidebarMenu: ISidebarItem[] = [];

    if (isSuperAdmin()) {
      sidebarMenu = sidebarItems.map((item) => {
        return {
          ...item,
          isShow: true,
        };
      });

      setSidebarItems(sidebarMenu);
    } else {
      setSidebarItems(SIDEBAR_ITEMLIST);
    }
    //eslint-disable-next-line
  }, []);

  if (props.isSidebarCollapsed) return <div></div>;

  return (
    <div className="sidebar">
      <div className="center">
        <Nav activeKey="/home">
          {sidebarItems.map((item, index) => {
            return item.isShow ? <ItemLink key={index} {...item} /> : <></>;
          })}
        </Nav>
      </div>

      <div className="bottom">
        <span>v {props.appVersion}</span>
      </div>
    </div>
  );
};

export default Sidebar;
