import { FC, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactElement, ReactNode } from "react";

import { ModalDialog } from "../../loadable";
import "./confirmModal.scss";
import ButtonCustom from "../button/button";

interface IConfirmModalProps {
  isShow: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onConfirm: (reason?: string) => void;
  title: string;
  body: string | ReactNode;
  cancelText?: string;
  cancelIcon?: ReactElement;
  confirmText?: string;
  confirmIcon?: ReactElement;
  requiredReason?: boolean;
  disableSubmitButton?: boolean;
}

const ConfirmModal: FC<IConfirmModalProps> = (props: IConfirmModalProps) => {
  const { t } = useTranslation();

  const [reason, setReason] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>(true);

  const handleConfirm = () => {
    if (props.requiredReason && !reason) {
      setIsValid(false);
      return;
    }
    props.onConfirm(reason);
  };

  const handleClose = () => {
    if (props.isLoading) {
      return;
    }

    setReason(undefined);
    setIsValid(true);
    props.onClose();
  };

  const handleChangeReason = (value: string) => {
    setReason(value);
    setIsValid(value ? true : false);
  };

  return (
    <ModalDialog show={props.isShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.body}
        {props.requiredReason && (
          <Form.Group>
            <Form.Control
              className="reason"
              as="textarea"
              placeholder={t("ENTER_REASON")}
              onChange={(e) => handleChangeReason(e.target.value)}
              value={reason}
              isInvalid={!isValid}
            />
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleClose}>
          {props.cancelIcon ? (
            <span className="me-1 mb-1">{props.cancelIcon}</span>
          ) : (
            <></>
          )}
          {props.cancelText ? props.cancelText : t("CANCEL")}
        </Button>

        {!props.disableSubmitButton && (
          <ButtonCustom variant="primary" isLoading={props.isLoading} onClick={handleConfirm}>
            {props.confirmIcon ? (
              <span className="me-2 mb-1">{props.confirmIcon}</span>
            ) : (
              <></>
            )}
            {props.confirmText ? props.confirmText : t("OK")}
          </ButtonCustom>
        )}
      </Modal.Footer>
    </ModalDialog>
  );
};

export default ConfirmModal;
