import Loadable from "react-loadable";
import Loading from "./components/loading";

const LoadableModal = Loadable({
  loader: () => import("react-bootstrap/Modal"),
  loading: Loading,
});
const LoadableAuthLoginCallback = Loadable({
  loader: () => import("./pages/authLoginCallback"),
  loading: Loading,
});
const LoadableBackdoorAuth = Loadable({
  loader: () => import("./pages/backdoorAuth"),
  loading: Loading,
});

const LoadableManagePersonnel = Loadable({
  loader: () => import("./pages/managePersonnelPage/managePersonnelPage"),
  loading: Loading,
});

const LoadableAllocateBudget = Loadable({
  loader: () => import("./pages/allocatePersonalBudget/allocatePersonalBudget"),
  loading: Loading,
});
const LoadableAllocateBudgetBs = Loadable({
  loader: () => import("./pages/allocateBudgetBs/allocateBudgetBs"),
  loading: Loading,
});

const LoadableAllocateBudgetLogs = Loadable({
  loader: () => import("./pages/allocateBudgetLogs/allocateBudgetLogs"),
  loading: Loading,
});

export const ModalDialog = LoadableModal;
export const AuthLoginCallback = LoadableAuthLoginCallback;
export const BackdoorAuth = LoadableBackdoorAuth;
export const AllocateBudgetBs = LoadableAllocateBudgetBs;
export const ManagePersonnel = LoadableManagePersonnel;
export const AllocatePersonalBudget = LoadableAllocateBudget;
export const AllocateBudgetLogs = LoadableAllocateBudgetLogs;
