export const TOOLTIP_DELAY = 500;
export const END_MONTH_OF_BUDGET_YEAR = "09";
export const END_DATE_OF_BUDGET_YEAR = "30";
export const BUDGET_YEAR_START = 2566;
export const BUDGET_YEAR_RANGE = 5;
export const DEBOUNCE_TIME = 500;
export const BUDDHIST_YEAR_GAP = 543;
export const START_DATE_OF_COUPON = "08";
export const END_DATE_OF_COUPON = "07";
export const START_DATE_OF_BUDGET_YEAR = "01";
export const START_MONTH_OF_BUDGET_YEAR = "10";
