import { FC, ReactElement, useEffect, useState } from "react";
import {
  Routes as Switch,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import { PATH } from "./constants/path";
import {
  AllocatePersonalBudget,
  AuthLoginCallback,
  BackdoorAuth,
  AllocateBudgetBs,
  AllocateBudgetLogs,
  ManagePersonnel,
} from "./loadable";
import { getBypassToken, getUserAuthorizationRequest } from "./utils/cmuAuth";
import { useUser } from "./hooks/useUser";
import { isEnableBypassAuth } from "./utils/bypass";

interface RoutesProps {
  sidebar: ReactElement;
  navbar: ReactElement;
}

// Assign "Pages" to a "/route".
const Routes: FC<RoutesProps> = ({ sidebar, navbar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuthValue, isAuthenticated } = useUser();

  const [toggleManagementPage, setToggleManagementPage] =
    useState<boolean>(false);
  const [toggleAllocatePage, setToggleAllocatePage] = useState<boolean>(false);

  const [stateSbuDisplay, setStateSbuDisplay] = useState<boolean>(false);

  const [stateSbuOption, setStateSbuOption] = useState<boolean>(false);

  useEffect(() => {
    setStateSbuDisplay(!stateSbuOption);
  }, [stateSbuOption]);

  useEffect(() => {
    if (location.pathname !== "/auth-call-back" && !isAuthenticated()) {
      triggerImmediateLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const triggerImmediateLogin = () => {
    const devRole = process.env.REACT_APP_DEV_ROLE;

    if (devRole) {
      //dev mode
      getBypassToken(devRole, undefined, setAuthValue)
        .then((data) => {
          navigate("/");
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      window.location.href = getUserAuthorizationRequest();
    }
  };
  return (
    <>
      {isAuthenticated() ? <>{navbar}</> : <></>}
      <div className="app">
        {isAuthenticated() ? <>{sidebar}</> : <></>}
        <div
          className="app-container"
          onScroll={() => {
            if (toggleManagementPage) {
              setToggleManagementPage(false);
            }
            if (toggleAllocatePage) {
              setToggleAllocatePage(false);
            }
            if(stateSbuOption){
              setStateSbuOption(false);
            }
          }}
        >
          <Switch>
            <Route
              key={PATH.MANAGE_PERSONNEL}
              path={PATH.MANAGE_PERSONNEL}
              element={
                <ManagePersonnel
                  togglePopupStatus={toggleManagementPage}
                  onScroll={(value: boolean) => {
                    setToggleManagementPage(value);
                  }}
                />
              }
            />

            <Route
              key={PATH.ALLOCATE_BUDGET_FOR_BU}
              path={PATH.ALLOCATE_BUDGET_FOR_BU}
              element={<AllocateBudgetBs />}
            />

            <Route
              key={PATH.ALLOCATE_BUDGET_FOR_BU}
              path={PATH.ALLOCATE_BUDGET_FOR_BU}
              element={<AllocateBudgetBs />}
            />

            <Route
              key={PATH.ALLOCATE_BUDGET_LOGS}
              path={PATH.ALLOCATE_BUDGET_LOGS}
              element={<AllocateBudgetLogs />}
            />

            {isEnableBypassAuth() && (
              <>
                <Route
                  key={PATH.TEST_LOGIN}
                  path={PATH.TEST_LOGIN}
                  element={<BackdoorAuth />}
                />
              </>
            )}

            <Route
              key={PATH.AUTH_CALLBACK}
              path={PATH.AUTH_CALLBACK}
              element={<AuthLoginCallback />}
            />

            <Route
              key={PATH.ALLOCATE_PERSONAL_BUDGET}
              path={PATH.ALLOCATE_PERSONAL_BUDGET}
              element={
                <AllocatePersonalBudget
                  togglePopupStatus={toggleAllocatePage}
                  onScroll={(value: boolean) => {
                    setToggleAllocatePage(value);
                  }}
                  setStateSbuOption={(value: boolean) => {
                    setStateSbuOption(value);
                  }}
                  stateSbuDisplay={stateSbuDisplay}
                  stateSbuOption={stateSbuOption}
                />
              }
            />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Routes;
