import { v4 as uuid } from "uuid";
import { ApiToken } from "../Interfaces/apiToken";
import client from "./axios";

export const getUserAuthorizationRequest = () => {
  let url: string = process.env.REACT_APP_CMU_AUTH_URL + "v1/Authorize.aspx?";
  url += "response_type=code";
  url += "&client_id=" + process.env.REACT_APP_CMU_AUTH_CLIENT_ID;
  url += "&redirect_uri=" + process.env.REACT_APP_CMU_AUTH_CALLBACK_URL;
  url += "&scope=mishr.self.basicinfo cmuitaccount.basicinfo";
  url += "&state=" + uuid();
  return url;
};

export const getToken = async (
  code: string,
  setToken: (token: string) => void
) => {
  return await client
    .post<ApiToken>(`api/v1/auth/cmu`, {
      code: code,
      callbackUrl: process.env.REACT_APP_CMU_AUTH_CALLBACK_URL,
    })
    .then((response) => {
      const token: string = response.data.token;
      localStorage.setItem("cmu-token", token);
      setToken(token);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getBypassToken = (
  role: string,
  email: string = "",
  setToken: (token: string) => void
) => {
  const roleId = Number(role) ?? 1;

  return client
    .post<ApiToken>(`api/v1/auth/bypass`, {
      id: 3, // if has any change about id, Please keep your change in local change only Don't push it to origin (P'Mate has been told)
      roleId: roleId,
      cmuItAccount: email,
    })
    .then((response) => {
      const token: string = response.data.token;
      localStorage.setItem("cmu-token", token);
      setToken(token);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const logout = (logOut: () => void) => {
  localStorage.removeItem("cmu-token");
  logOut();
  window.location.href = getUserAuthorizationRequest();
};
