import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import { User } from '../Interfaces/apiToken';

export interface IAuthContextProps {
  auth: string | undefined;
  isAuthenticated: () => boolean;
  setAuthValue: (token:string) => void;
  user: User | undefined;
  logOut: () => void;
}

export const AuthContext = createContext<IAuthContextProps>({
    auth: undefined,
    isAuthenticated: ():boolean=> false, 
    setAuthValue: (token:string)=>{},
    user: undefined,
    logOut: () => {},
  });

export interface IAuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: FC<IAuthProviderProps> = ({children}) => {
  const [auth, setAuth] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<User | undefined>(undefined);
  useEffect(() => {
    const isAuth = () => {
      try {
        let user: User | undefined = undefined;
    
      if (auth) {
      user = jwt_decode(auth) as User;
      user.roleId = Number(user?.roleId);
    }
    setUser(user);
      } catch(error) {
        setUser(undefined);
        localStorage.removeItem("cmu-token");
      };
    };

    isAuth();
  }, [auth]);

  const setAuthValue = (token:string) => {
    setAuth(token);
  };

  const logOut = () => {
    setAuth(undefined);
    setUser(undefined);
  }

  const isAuthenticated = (): boolean =>{
    const localStorageToken = localStorage.getItem("cmu-token");
    if(auth){
      return true;
    }
    if(localStorageToken){
      setAuthValue(localStorageToken);
      return true;
    }
    return false;
  }

  return (
     <AuthContext.Provider value={{ auth, isAuthenticated, setAuthValue, user, logOut }} >
       {children}
    </AuthContext.Provider>
  );
};
