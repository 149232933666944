import { SvgIconTypeMap } from "@mui/material/SvgIcon";
import { PATH } from "./path";

// **** Just copy import from this repository in the link below. ****
// https://mui.com/material-ui/material-icons/
// ******************************************************************
import { OverridableComponent } from "@mui/material/OverridableComponent";
import People from "../assets/svg/people.svg";
import Coin from "../assets/svg/coin.svg";
import PersonalSync from "../assets/svg/person-sync.svg";
import HistoryIcon from "../assets/svg/history_stoke.svg";

export interface ISidebarItem {
  path?: string;
  text: string;
  icon?: string | OverridableComponent<SvgIconTypeMap>;
  isgroup: boolean;
  isShow: boolean;
  group: ISidebarItem[];
}

export const SIDEBAR_ITEMLIST: ISidebarItem[] = [
  {
    path: PATH.MANAGE_PERSONNEL,
    text: "MANAGE_PERSONNEL",
    icon: People,
    isgroup: false,
    isShow: true,
    group: [],
  },
  {
    path: PATH.ALLOCATE_BUDGET_FOR_BU,
    text: "SIDEBAR_ALLOCATE_BUDGET_FOR_BU",
    icon: Coin,
    isgroup: false,
    isShow: true,
    group: [],
  },
  {
    path: PATH.ALLOCATE_PERSONAL_BUDGET,
    text: "ALLOCATE_BUDGET_FOR_PERSONAL",
    icon: PersonalSync,
    isgroup: false,
    isShow: true,
    group: [],
  },
  {
    path: PATH.ALLOCATE_BUDGET_LOGS,
    text: "HISTORY_BUDGET_MANAGEMENT",
    icon: HistoryIcon,
    isgroup: false,
    isShow: true,
    group: [],
  },
];
